import React from "react";
import { Box, Container } from "@mui/material";
import grey from "@mui/material/colors/grey";

import Header from "../utils/Header";
import Footer from "../utils/Footer";
import TopImage from "./components/TopImage";
import Positions from "./components/Positions";
import WorkStyles from "./components/WorkStyles";

const RecruitBody = () => {
  return (
    <Box>
      <Box px={{ xs: 2, sm: 2, md: 15, lg: 30 }}>
        <Container sx={{ py: 8 }}>
          <Positions />
        </Container>
      </Box>
      <Box px={{ xs: 0, sm: 2, md: 15, lg: 30 }} sx={{ bgcolor: grey[200] }}>
        <Container sx={{ py: 8 }}>
          <WorkStyles />
        </Container>
      </Box>
    </Box>
  );
};

const RecuritTop = () => {
  // 採用ページトップ
  return (
    <>
      <Header />
      <TopImage />
      <RecruitBody />
      <Footer />
    </>
  );
};

export default RecuritTop;
