import React from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import grey from "@mui/material/colors/grey";

function srcset(image, size, rows = 1, cols = 1) {
  // row x col サイズに画像を crop
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemDataMd = [
  {
    img: "./images/cafeteria.webp",
    alt: "カフェテリアの写真",
    rows: 3,
    cols: 2,
  },
  {
    img: "./images/in_portugal.jpg",
    alt: "ポルトガル出張時の写真",
    rows: 3,
  },
  {
    img: "./images/sofar.jpg",
    alt: "ソファーで打ち合わせをする社員の写真",
    rows: 3,
  },
  {
    img: "./images/lobby.jpg",
    alt: "ロビーの写真",
    rows: 3,
    cols: 1,
  },
  {
    img: "./images/window.jpg",
    title: "窓際で作業する社員の写真",
    rows: 3,
    cols: 3,
  },
];

const itemDataSm = [
  {
    img: "./images/cafeteria.webp",
    alt: "カフェテリアの写真",
    rows: 3,
    cols: 3,
  },
  {
    img: "./images/sofar.jpg",
    alt: "ソファーで打ち合わせをする社員の写真",
    rows: 3,
    cols: 2,
  },
  {
    img: "./images/lobby.jpg",
    alt: "ロビーの写真",
    rows: 3,
    cols: 2,
  },
  {
    img: "./images/window.jpg",
    title: "窓際で作業する社員の写真",
    rows: 3,
    cols: 3,
  },
];

function QuiltedImageList() {
  // タイル状の画像リスト

  // 画面サイズによって画像を変える

  const is_sm = useMediaQuery("(min-width:600px)");
  const itemData = is_sm ? itemDataMd : itemDataSm;
  const cols = is_sm ? 4 : 5;

  return (
    <ImageList
      sx={{ width: "100%", my: 0 }}
      variant="quilted"
      cols={cols}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const TopImage = () => {
  // 画像とメッセージを重ねて表示

  const message = (
    <>
      デジタル技術を最適化し、
      <br />
      ビジネスを革新する <br />
      エキスパート集団
    </>
  );

  return (
    <Box sx={{ background: "white", position: "relative" }}>
      <QuiltedImageList />
      <Box
        sx={{
          position: "absolute",
          bgcolor: "rgba(0, 0, 0, 0.3)",
          minHeight: "100%",
          top: "0",
          minWidth: "100%",
          right: "0",
        }}
      >
        <Box
          px={{ xs: 2, sm: 2, md: 10, xl: 25 }}
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translate(0%, -50%)",
          }}
        >
          <Container>
            <Typography variant="h2" component="h1" sx={{ color: grey[300] }}>
              採用情報
            </Typography>
            <Typography
              variant="catchphrase"
              component="h1"
              fontSize={{ xs: "2rem", sm: "3rem", md: "4rem" }}
              sx={{ color: "white" }}
            >
              {message}
            </Typography>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default TopImage;
