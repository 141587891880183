import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

const Logo = () => {
  return (
    <a href="https://d-experts.com/">
      <img src="./images/de_logo.webp" alt="logo" height={30}></img>
    </a>
  );
};

function Header() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} sx={{ background: "#FFFFFF" }}>
        <Toolbar>
          <Logo />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default Header;
