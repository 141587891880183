import React from "react";
import { Container, Typography, Box } from "@mui/material";
import styles from "./PositionCard.module.css";
import grey from "@mui/material/colors/grey";

const PositionCard = ({ title, position, salary, target, period, link }) => (
  <div className={styles.card}>
    <a href={link} className={styles.a}>
      <div className={styles.cardContent}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.position}>{position}</div>
        <div className={styles.infoGrid}>
          <div className={styles.infoItem}>
            <span className={styles.infoLabel}>給与</span>
            <span className={styles.infoValue}>{salary}</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.infoLabel}>対象</span>
            <span className={styles.infoValue}>{target}</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.infoLabel}>募集期間</span>
            <span className={styles.infoValue}>{period}</span>
          </div>
        </div>
      </div>
    </a>
  </div>
);

const PositionCards = () => (
  <div className={styles.cardContainer}>
    <PositionCard
      title="インターン生"
      position="ソフトウェアエンジニア"
      salary="時給 2500円 〜 5000円"
      target="学生"
      period="通年"
      link="/intern"
    />
    <PositionCard
      title="正社員"
      position="ソフトウェアエンジニア"
      salary="年収 600万円 ～ 1800万円"
      target="制限なし"
      period="通年"
      link="/full-time"
    />
  </div>
);

const Positions = () => {
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          component="h2"
          color="primary"
          sx={{ fontSize: "2rem", fontWeight: 700, letterSpacing: "0.1em" }}
        >
          POSITIONS
        </Typography>
        <Typography component="h2" color="primary" sx={{ fontSize: "1rem", letterSpacing: "0.05em"  }}>
          募集中のポジション
        </Typography>
      </Box>
      <Container sx={{ py: 8 }}>
        <PositionCards />
      </Container>
    </>
  );
};

export default Positions;
