import React from "react";
import { Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Title = ({ contractType, occupation }) => {
  return (
    <>
      <Typography variant="h1" component="h1" color="primary">
        {" "}
        {contractType}{" "}
      </Typography>
      <Typography variant="body1" component="body1" color={grey[700]}>
        {" "}
        {occupation}{" "}
      </Typography>
    </>
  );
};

const EntryButton = ({ link }) => {
  return (
    <Button
      sx={{ py: 2, px: 5, fontWeight: 500, fontSize: "1rem" }}
      variant="contained"
      href={link}
    >
      Entry
    </Button>
  );
};

const JobDescription = ({ JobDescriptionText }) => {
  return (
    <>
      <Typography variant="h2" component="h2" color="primary.light">
        {" "}
        職務内容{" "}
      </Typography>
      <Container sx={{ py: 1 }}>
        <Typography variant="body1" component="body1">
          {JobDescriptionText}
        </Typography>
      </Container>
    </>
  );
};

const Required = ({ essentialCriteria, desirableCriteria }) => {
  return (
    <>
      <Container sx={{ py: 1 }}>
        <Typography variant="h2" component="h2" color="primary.light">
          {" "}
          必須要件{" "}
        </Typography>
        <Container sx={{ py: 0 }}>
          <Typography variant="body1" component="body1">
            {essentialCriteria}
          </Typography>
        </Container>
      </Container>
      <Container sx={{ py: 1 }}>
        <Typography variant="h2" component="h2" color="primary.light">
          {" "}
          歓迎要件{" "}
        </Typography>
        <Container sx={{ py: 0 }}>
          <Typography variant="body1" component="body1">
            {desirableCriteria}
          </Typography>
        </Container>
      </Container>
    </>
  );
};

function rowDataToJSX(date) {
  return date.split("\n").map((line) => (
    <>
      {line}
      <br />
    </>
  ));
}

const DetailsTable = ({ rows }) => {
  return (
    <>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ maxWidth: "100%" }}
      >
        <Table aria-label="simple table" sx={{ p: 0, m: 0 }}>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" bgcolor={grey[100]}>
                  <Typography
                    variant="body"
                    component="body"
                    bgcolor="transparent"
                  >
                    {" "}
                    {row.name}{" "}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2" component="body2">
                    {rowDataToJSX(row.data)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const DetailsTableXs = ({ rows }) => {
  return (
    <>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ maxWidth: "100%" }}
      >
        <Table aria-label="simple table" sx={{ p: 0, m: 0 }}>
          <TableBody>
            {rows.map((row) => (
              <>
                <TableRow
                  key={row.name}
                  bgcolor={grey[100]}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="body2" component="body2">
                      {" "}
                      {row.name}{" "}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="body2" component="body2">
                      {rowDataToJSX(row.data)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const Details = ({ tableRows }) => {
  return (
    <>
      <Typography variant="h2" component="h2" color="primary.light">
        {" "}
        応募概要{" "}
      </Typography>
      <Container sx={{ py: 3 }}>
        <Box display={{ xs: "none", md: "flex" }}>
          <DetailsTable rows={tableRows} />
        </Box>
        <Box display={{ xs: "flex", md: "none" }}>
          <DetailsTableXs rows={tableRows} />
        </Box>
      </Container>
    </>
  );
};

const ProcessPaper = ({ title, icon, description }) => {
  return (
    <Paper variant="outlined" square={false}>
      <Box sx={{ py: 3, textAlign: "center", bgcolor: grey[200] }}>
        <Typography
          sx={{
            mb: 1.5,
            color: grey[700],
            fontSize: "1.1rem",
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
        {icon}
        <Box
          minHeight="70px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="body"
            component="body"
            bgcolor="transparent"
            color={grey[700]}
          >
            {rowDataToJSX(description)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const ProcessPaperXs = ({ title, icon, description }) => {
  return (
    <Paper variant="outlined" square={false}>
      <Box
        sx={{
          py: 3,
          px: 5,
          textAlign: "center",
          bgcolor: grey[200],
          width: "150px",
        }}
      >
        <Typography
          sx={{
            mb: 1.5,
            color: grey[700],
            fontSize: "1.5rem",
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {icon}
        <Box>
          <Typography
            variant="body"
            component="body"
            bgcolor="transparent"
            color={grey[700]}
          >
            {rowDataToJSX(description)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const ProsessPapers = ({ processInfo }) => {
  function Rightarrow() {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: 100, color: grey[400] }} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box
        display={{ xs: "flex", lg: "none" }}
        sx={{ alignItems: "center", flexFlow: "column" }}
      >
        {processInfo.map((info, idx) => (
          <>
            <ProcessPaperXs
              title={info.title}
              icon={info.icon}
              description={info.description}
            />
            {idx === processInfo.length - 1 ? null : (
              <ArrowDropDownIcon sx={{ fontSize: 100, color: grey[400] }} />
            )}
          </>
        ))}
      </Box>
      <Grid
        container
        display={{ xs: "none", lg: "flex" }}
        spacing={{ md: 3 }}
        columns={{ md: 11 }}
        rowSpacing={{ xs: 2, md: 4 }}
        justifyContent="center"
      >
        {processInfo.map((info, idx) => (
          <>
            <Grid xs={12} sm={6} md={3}>
              <ProcessPaper
                title={info.title}
                icon={info.icon}
                description={info.description}
              />
            </Grid>
            {idx === processInfo.length - 1 ? null : (
              <Grid xs={12} sm={6} md={1}>
                <Rightarrow />
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </>
  );
};

const SelectionProcess = ({ processInfo }) => {
  return (
    <>
      <Typography variant="h2" component="h2" color="primary.light">
        {" "}
        選考プロセス{" "}
      </Typography>
      <Container sx={{ py: 3, textAlign: "center" }}>
        <ProsessPapers processInfo={processInfo} />
      </Container>
    </>
  );
};

const JobBody = ({
  contractType,
  occupation,
  formLink,
  JobDescriptionText,
  essentialCriteria,
  desirableCriteria,
  tableRows,
  processInfo,
}) => {
  return (
    <Box
      elevetion={3}
      sx={{ py: 6, display: "flex", justifyContent: "center" }}
      px={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 32}}
    >
      <Paper square={false} sx={{ width: "100%", px: 0, maxWidth: "1000px" }}>
        <Box
          sx={{
            px: 4,
            position: "sticky",
            top: 0,
            zIndex: 1,
            bgcolor: "white",
            borderBottom: "solid",
            borderColor: grey[200],
          }}
        >
          <Container
            sx={{
              py: 4,
              px: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ px: 0 }}>
              <Title contractType={contractType} occupation={occupation} />
            </Box>
            <Box sx={{ display: "flex" }}>
              <EntryButton link={formLink} />
            </Box>
          </Container>
        </Box>
        <Box sx={{ py: 3 }} px={{ xs: 2, sm: 4, md: 4, lg: 4 }}>
          <Container sx={{ py: 1 }}>
            <JobDescription JobDescriptionText={JobDescriptionText} />
          </Container>
          <Required
            essentialCriteria={essentialCriteria}
            desirableCriteria={desirableCriteria}
          />
          <Container sx={{ py: 1 }}>
            <Details tableRows={tableRows} />
          </Container>
          <Container sx={{ py: 1 }}>
            <SelectionProcess processInfo={processInfo} />
          </Container>
        </Box>
      </Paper>
    </Box>
  );
};

export default JobBody;
