import React from "react";
import { Box, Container, Typography } from "@mui/material";
import grey from "@mui/material/colors/grey";

const Footer = () => {
  return (
    <Container
      sx={{
        p: 0,
        m: 0,
        background: grey[300],
        minWidth: "100%",
        minHeight: "90px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", py: 1 }}>
        <Typography
          variant="caption"
          component="caption"
          color={grey[600]}
          px={1}
        >
          <a
            href="https://d-experts.com/legal"
            style={{ textDecoration: "none", color: grey[700], fontWeight: 400 }}
          >
            サイト利用規約
          </a>
        </Typography>
        <Typography
          variant="caption"
          component="caption"
          color={grey[600]}
          px={1}
        >
          <a
            href="https://d-experts.com/privacy"
            style={{ textDecoration: "none", color: grey[700], fontWeight: 400  }}
          >
            プライバシーポリシー
          </a>
        </Typography>
      </Box>
      <Typography variant="caption" component="caption" color={grey[600]}>
        {" "}
        © 2024 Digital Experts All Right Reserved.{" "}
      </Typography>
    </Container>
  );
};

export default Footer;
