import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { responsiveFontSizes } from "@mui/material/styles";
import { grey, teal } from "@mui/material/colors";

import RecruitTop from "./top/RecruitTop";
import Intern from "./jobs/Intern";
import Fulltime from "./jobs/Fulltime";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RecruitTop />,
  },
  {
    path: "/intern",
    element: <Intern />,
  },
  {
    path: "/full-time",
    element: <Fulltime />,
  },
]);

function App() {
  let theme = createTheme({
    palette: {
      primary: { main: teal[500], light: teal[300], dark: teal[700] },
      background: {
        default: grey[100],
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        '"Noto Sans JP"',
        '"Helvetica"',
        "Arial",
        "sans-serif",
      ].join(","),
      h1: {
        fontSize: "2rem",
        fontWeight: 500,
        color: grey[800],
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: 500,
        color: grey[800],
      },
      h3: {
        fontSize: "1rem",
        fontWeight: 500,
        color: grey[800],
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        color: grey[800],
      },
      body2: {
        fontSize: "0.85rem",
        fontWeight: 300,
        color: grey[800],
      },
      caption: {
        fontSize: "0.75rem",
        fontWeight: 300,
        color: grey[800],
      },
      catchphrase: {
        fontSize: "4rem",
        fontWeight: 700,
        color: grey[800],
      },
    },
  });
  theme = responsiveFontSizes(theme);
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
